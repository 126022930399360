<template>
	<article
		class="d-content-page"
		:class="{
			'd-content-page--no-footer-gap': !hasFooterGap,
		}"
	>
		<PageHeader
			:intro="intro"
			:icon-url="iconUrl"
			:details="details"
			:layout="template === 'publication' ? 'publication' : 'default'"
		/>

		<LongReadController
			id="long-read-controller"
			:key="$route.path"
			v-slot="longReadController"
		>
			<PageProgressBar
				v-if="showToc || links"
				:class="{
					'<1024:hidden': !showToc,
				}"
			>
				<!-- TOC -->
				<PageProgressBarToc
					v-if="showToc"
					v-bind="longReadController"
					:placeholder="toc.title"
					:toc-to-top="toc.toTheTop"
				/>

				<!-- Links -->
				<LinkDropdown
					v-if="links"
					class="ml-auto <1024:hidden min-w-280 mr-layout-margin"
					:links="links.items"
					:label="links.label"
					:description="links.description"
				/>
			</PageProgressBar>

			<!-- Links -->
			<LinkDropdown
				v-if="links"
				class="
					w-fit
					min-w-280
					mx-auto
					mt-16
					<768:w-full
					>=1024:hidden
					px-layout-margin
				"
				:links="links.items"
				:label="links.label"
				:description="links.description"
				all-red
			/>

			<div
				class="
					grid grid-cols-12
					items-start
					gap-y-2xl/v
					px-layout-margin
					mt-2xl/v
				"
			>
				<aside class="col-span-full >=1024:col-span-3">
					<AsideContactPersons
						v-if="contactPersons && contactPersons.length"
						:contact-persons="contactPersons"
					/>
				</aside>

				<div
					class="
						col-span-full
						>=1024:col-start-5 >=1024:col-span-8
						grid grid-cols-12
						>=1024:grid-cols-8
						items-start
						space-y-2xl/v
					"
				>
					<p
						v-if="intro && intro.teaser"
						class="
							col-span-full
							font-light
							text-sub-teaser text-red
						"
						v-text="intro.teaser"
					></p>

					<BlockList
						v-if="blocks && blocks.length"
						:block-list="blocks"
						class="col-span-full"
					/>

					<!-- People and about on projects and publications -->
					<section
						v-if="aboutSection"
						class="
							d-content-page__about
							isolate
							relative
							w-12/12col
							justify-self-end
							col-span-full
							>=1024:col-start-5 >=1024:col-span-8
							pb-sm/v
						"
						:class="{
							'pt-0 mt-0': lastBlockIsGrayBlock,
							'pt-sm/v': !lastBlockIsGrayBlock,
						}"
					>
						<div
							v-for="(group, index) in aboutSection"
							:id="`d-content-page__about-${_uid}-${index}`"
							:key="index"
							class="py-lg/v grid grid-cols-12 gap-y-xs/v"
							:class="{
								'border-t border-gray-medium':
									lastBlockIsGrayBlock || index,
							}"
						>
							<h2
								v-if="group.title"
								class="
									text-search-heading text-gray-darker
									col-span-full
									>=1024:col-span-3
									>=1400:col-span-2
								"
								v-text="group.title"
							></h2>

							<ul
								class="
									col-span-full
									>=1024:col-span-8 >=1024:col-start-5
									grid grid-cols-8
									gap-y-2xs/v
									text-body
								"
							>
								<!-- Names -->
								<li
									v-for="(item, itemIndex) in group.items"
									:key="itemIndex"
									class="
										col-span-full
										grid grid-cols-1
										content-start
										gap-y-4
									"
									:class="{
										'>=1024:col-span-8':
											group.items.length === 1,
										'>=1024:col-span-3 >=1024:pr-sm/h':
											group.items.length > 1 &&
											(group.items.length - itemIndex) %
												2 ===
												0,
										'>=1024:col-span-5':
											group.items.length > 1 &&
											(group.items.length - itemIndex) %
												2 ===
												1,
									}"
								>
									<h3
										v-if="item.label"
										class="font-medium"
										v-text="item.label"
									></h3>

									<div
										v-if="item.value"
										class="
											d-content-page__people-list
											font-light
										"
									>
										<template
											v-for="(
												value, nameIndex
											) in item.value"
										>
											<span
												v-if="value.url"
												:key="nameIndex + '-link'"
											>
												<NuxtLinkExt
													:to="value.url"
													class="text-red underline"
													v-text="
														[
															value.name,
															value.comment &&
																`(${value.comment})`,
														]
															.filter(Boolean)
															.join(' ')
													"
												/>
											</span>
											<span
												v-else
												:key="nameIndex"
												v-text="
													[
														value.name,
														value.comment &&
															`(${value.comment})`,
													]
														.filter(Boolean)
														.join(' ')
												"
											></span>
										</template>
									</div>
								</li>

								<li
									v-for="(field, fieldIndex) in group.fields"
									:key="fieldIndex"
									class="
										col-span-full
										grid grid-cols-1
										content-start
										gap-y-4
									"
									:class="{
										'>=1024:col-span-8':
											group.fields.length === 1,
										'>=1024:col-span-3 >=1024:pr-sm/h':
											group.fields.length > 1 &&
											(group.fields.length - fieldIndex) %
												2 ===
												0,
										'>=1024:col-span-5':
											group.fields.length > 1 &&
											(group.fields.length - fieldIndex) %
												2 ===
												1,
									}"
								>
									<h3
										v-if="field.label"
										class="font-medium"
										v-text="field.label"
									></h3>
									<div
										v-if="field.value"
										class="font-light"
										v-text="field.value"
									></div>
								</li>
							</ul>
						</div>
					</section>
				</div>
			</div>
		</LongReadController>
	</article>
</template>

<script>
import PageHeader from '~/components/main/PageHeader.vue';
import PageProgressBar from '~/components/main/PageProgressBar';
import { LongReadController } from '~/components/shared/LongRead';
import PageProgressBarToc from '~/components/main/PageProgressBarToc';
import LinkDropdown from '~/components/main/LinkDropdown';
import AsideContactPersons from '~/components/main/AsideContactPersons';
import BlockList from '~/components/main/BlockList';

export default {
	name: 'MainContentPage',

	components: {
		PageHeader,
		PageProgressBar,
		LongReadController,
		PageProgressBarToc,
		LinkDropdown,
		AsideContactPersons,
		BlockList,
	},

	inheritAttrs: false,

	props: {
		intro: Object,
		iconUrl: String,
		details: {
			type: Object,
			default: () => ({}),
		},
		showToc: Boolean,
		contactPersons: {
			type: Array,
			default: () => [],
		},
		blocks: {
			type: Array,
			default: () => [],
		},
		toc: {
			type: Object,
			required: false,
		},
		template: String,
	},

	head() {
		console.log('yooo', this.details);

		/** ISO 8601 date */
		if (this.details?.date8601?.length || this.details?.time8601?.length) {
			return {
				meta: [
					{
						hid: 'iso8601-date',
						name: 'page_date',
						content: this.details?.date8601?.length
							? this.details.date8601
							: this.details.time8601,
					},
					{
						hid: 'iso8601-date-cludo',
						property: 'cludo:date',
						content: this.details?.date8601?.length
							? this.details.date8601
							: this.details.time8601,
					},
				],
			};
		}

		return {};
	},

	computed: {
		links() {
			return this.details?.links?.items?.length && this.details.links;
		},

		lastBlockIsGrayBlock() {
			const lastBlock = [...this.blocks].pop();
			if (
				lastBlock &&
				[
					'relatedContent',
					'contactPersons',
					'contactPersonExternal',
					'experts',
				].includes(lastBlock.alias)
			) {
				return true;
			}
			return false;
		},

		aboutSection() {
			const array = [this.details?.people, this.details?.about]
				.filter(Boolean)
				.filter(
					(group) => group?.items?.length || group?.fields?.length
				);
			return array.length ? array : null;
		},

		hasFooterGap() {
			if (this.aboutSection) {
				return false;
			}

			if (this.lastBlockIsGrayBlock) {
				return false;
			}

			return true;
		},
	},
};
</script>

<style lang="postcss">
.d-content-page__about {
	/* to be able to overwrite the space-y- values */
	&:is(.mt-0, #specificity-enforcer) {
		margin-top: 0 !important;
	}

	&:before {
		@apply absolute w-visual-screen top-0 h-full bg-gray-light;
		left: calc((100% - var(--visual-viewport-width, 100vw)) / 2);
		z-index: -1;
		content: '';
	}
}

/* Add separator between names/items */
.d-content-page__people-list {
	& > *:not(:last-child):not(:nth-last-child(2)):after {
		content: ', ';
	}
	& > *:nth-last-child(2):after {
		content: ' & ';
	}
}
</style>
